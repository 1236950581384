import { PhoneField as PhoneFieldType, FieldError } from '@/generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValue } from '@/hooks/useGravityForm';
import { getFieldParamsValue } from '@/utils/getFieldParamsValue';

interface Props {
    field: PhoneFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: NonNullable<unknown>;
}

const DEFAULT_VALUE = '';

export default function PhoneField({ field, fieldErrors, params, showField }: Props) {
    const { id, type, label, description, cssClass, isRequired, placeholder, adminLabel, labelPlacement } = field;
    const paramValue = adminLabel ? getFieldParamsValue(params, adminLabel) : null;
    const htmlId = `field_${id}`;
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValue | undefined;
    const value = paramValue || fieldValue?.value || DEFAULT_VALUE;

    if (!showField) return null;

    return (
        <div className={`gfield gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            {labelPlacement !== 'HIDDEN' && <label htmlFor={htmlId}>{label}</label>}
            {description ? <div className="field-description text-annotationText">{description}</div> : null}
            <input
                type="tel"
                name={String(id)}
                id={htmlId}
                required={Boolean(isRequired)}
                placeholder={placeholder || ''}
                defaultValue={value}
                onChange={event => {
                    dispatch({
                        type: ACTION_TYPES.updatePhoneFieldValue,
                        fieldValue: {
                            id,
                            value: event.target.value
                        }
                    });
                }}
            />
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          Please enter a valid phone number
                      </p>
                  ))
                : null}
        </div>
    );
}
