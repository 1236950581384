import { TimeField as TimeFieldType, FieldError } from '../../../../generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValue } from '../../../../hooks/useGravityForm';

interface Props {
    field: TimeFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: any;
}

const DEFAULT_VALUE = '';

export default function TimeField({ field, fieldErrors, showField }: Props) {
    const { id, type, label, description, cssClass, isRequired } = field;
    const htmlId = `field_${id}`;
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValue | undefined;
    const value = fieldValue?.value || DEFAULT_VALUE;

    if (!showField) return null;

    return (
        <div className={`gfield gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            <label htmlFor={htmlId}>{label}</label>
            <input
                type="time"
                name={String(id)}
                id={htmlId}
                required={Boolean(isRequired)}
                defaultValue={value}
                onChange={event => {
                    dispatch({
                        type: ACTION_TYPES.updateTimeFieldValue,
                        fieldValue: {
                            id,
                            value: event.target.value
                        }
                    });
                }}
            />
            {description ? <p className="field-description">{description}</p> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
        </div>
    );
}
