import * as React from 'react';
import cx from 'classnames';

interface ContainerProps {
    className?: string;
    as?: any;
    width?: string | null;
    customWidth?: string | null;
    display?: string;
    style?: { [key: string]: string };
    header?: boolean;
    transHeader?: boolean;
    children?: any;
}

const Container: React.FunctionComponent<ContainerProps> = ({ as: ElementType = 'div', className, transHeader, header, children, width = 'normal', customWidth, ...props }) => {
    let maxWidthClasses: string = '',
        customStyle: { [key: string]: string };

    // options for container width props
    switch (width) {
        case 'noClasses':
            maxWidthClasses = '';
            break;
        case 'narrow':
            maxWidthClasses = 'md:max-w-[720px]';
            break;
        case 'normal':
            maxWidthClasses = 'max-w-[720px] lg:max-w-[960px] 2xl:max-w-[1140px]';
            break;
        case 'wide':
            maxWidthClasses = 'max-w-[1600px]';
            break;
        case 'full':
            maxWidthClasses = 'w-full';
            break;
        case 'custom': {
            if (customWidth?.includes('px') || customWidth?.includes('rem')) {
                customStyle = {
                    maxWidth: customWidth
                };
            } else {
                maxWidthClasses = width;
            }

            break;
        }
    }

    return (
        <ElementType className={cx('container-div z-10 mx-auto', width === 'custom' ? 'container' : maxWidthClasses, className)} {...props}>
            <div className={cx([header && 'header flex w-full', 'mx-auto', transHeader && 'justify-center bsm:justify-start'])} style={customStyle}>
                {children}
            </div>
        </ElementType>
    );
};

export default Container;
