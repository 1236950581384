import cx from 'classnames';

export default function SectionField({ field, showField }) {
    const { label, cssClass } = field;

    if (!showField) return <div className="clear-both block h-1 w-full" />;
    return (
        <div className={cx(['relative flex w-full items-center border-stone-500 py-5', cssClass])}>
            <div className={cx(['flex-grow border-t'])}></div>
            {label && <span className="mx-4 flex-shrink">{label}</span>}
            <div className={cx(['flex-grow border-t'])}></div>
        </div>
    );
}
