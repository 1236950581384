import { NumberField as NumberFieldType, FieldError } from '@/generated/graphql';
import useGravityForm, { ACTION_TYPES } from '@/hooks/useGravityForm';

interface Props {
    field: NumberFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: NonNullable<unknown>;
}

const DEFAULT_VALUE = '';

export default function NumberField(props: Props) {
    const { dispatch } = useGravityForm();
    const { field, fieldErrors, showField } = props;
    if (!showField) return null;
    const { id, type, cssClass, defaultValue, label, description, isRequired, placeholder, numberFormat, labelPlacement } = field;
    const htmlId = `field_${id}`;

    const formatValue = (value: string) => {
        if (numberFormat === 'DECIMAL_DOT') {
            const decimalSeparator = '.';
            const formattedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric and non-decimal characters
            const parts = formattedValue.split(decimalSeparator); // Split the value into integer and decimal parts

            // Format the integer part with thousand separators
            // const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            const integerPart = parts[0];

            // Join the integer and decimal parts with the decimal separator
            // let formattedNumber = integerPart;
            let formattedNumber = integerPart;
            if (parts.length > 1) {
                const decimalPart = parts.slice(1).join(decimalSeparator);
                formattedNumber += decimalSeparator + decimalPart;
            }

            return formattedNumber;
        }

        return value;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = formatValue(e.target.value);
        // Update the field value in the form state
        dispatch({
            type: ACTION_TYPES.updateNumberFieldValue,
            fieldValue: {
                id,
                value
            }
        });
    };

    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
        const value = formatValue(e.currentTarget.value);
        e.currentTarget.value = value; // Update the displayed value
        // Update the field value in the form state
        dispatch({
            type: ACTION_TYPES.updateNumberFieldValue,
            fieldValue: {
                id,
                value
            }
        });
    };

    return (
        <div className={`gfield gfield-${type} ${cssClass && cssClass}`.trim()}>
            {label && labelPlacement !== 'HIDDEN' && <label htmlFor={htmlId}>{label}</label>}
            {description ? <div className="field-description text-annotationText">{description}</div> : null}
            <input
                type="text"
                id={htmlId}
                name={htmlId}
                defaultValue={formatValue(defaultValue || DEFAULT_VALUE)}
                placeholder={placeholder}
                required={isRequired}
                onChange={handleChange}
                onBlur={handleInput}
            />
            {fieldErrors?.length > 0 && (
                <div className="error-messages">
                    {fieldErrors.map(fieldError => (
                        <p key={fieldError.id} className="error-message">
                            {fieldError.message}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
}
