import { useEffect, useState } from 'react';

export default function useDefaultConditionalLogic(formFields, logicFieldInfo) {
    const [formFieldLogic, setFormFieldLogic] = useState([]);

    useEffect(() => {
        const checkFieldConditionalLogic = conditionalLogic => {
            const { actionType, logicType, rules } = conditionalLogic;
            const showOrHideArray = [];
            for (const rule of rules) {
                const { operator, fieldId, value } = rule;
                const logicFieldInfoFilter = logicFieldInfo.filter(f => f.fieldId === fieldId).reverse();
                const fieldInfoVal = logicFieldInfoFilter[0]?.value;
                // console.log('a', fieldInfoVal);
                const logicFieldInfoValue = fieldInfoVal && (typeof fieldInfoVal === 'string' ? fieldInfoVal : fieldInfoVal[0]?.value);
                if (operator === 'IS') {
                    if (logicFieldInfoValue === value) {
                        showOrHideArray.push(actionType === 'SHOW');
                    } else {
                        if (!logicFieldInfoValue && !value) {
                            showOrHideArray.push(actionType === 'SHOW');
                        } else showOrHideArray.push(actionType === 'HIDE');
                    }
                } else if (operator === 'IS_NOT') {
                    if (logicFieldInfoValue === value) {
                        showOrHideArray.push(actionType === 'HIDE');
                    } else {
                        if (!logicFieldInfoValue && !value) {
                            showOrHideArray.push(actionType === 'HIDE');
                        }
                        showOrHideArray.push(actionType === 'SHOW');
                    }
                }
            }
            let showOrHideArrayResult;
            if (logicType === 'ALL') {
                if (actionType === 'SHOW') showOrHideArrayResult = showOrHideArray.every(val => val === true);
                else showOrHideArrayResult = showOrHideArray.some(val => val === true);
            } else {
                if (actionType === 'SHOW') showOrHideArrayResult = showOrHideArray.some(val => val === true);
                else showOrHideArrayResult = showOrHideArray.every(val => val === true);
            }
            return showOrHideArrayResult;
        };

        for (const field of formFields) {
            const { id, conditionalLogic } = field;
            if (conditionalLogic) {
                setFormFieldLogic(formFieldLogic => [...formFieldLogic, { fieldId: id, showField: checkFieldConditionalLogic(conditionalLogic) }]);
            }
        }
    }, [formFields, logicFieldInfo]);

    const uniqueIds = [];
    const formFieldLogicFilter = formFieldLogic.reverse().filter(f => {
        const isDuplicate = uniqueIds.includes(f.fieldId);
        if (!isDuplicate) {
            uniqueIds.push(f.fieldId);
            return f;
        }
    });
    if (formFieldLogicFilter.length !== formFieldLogic.length) {
        setFormFieldLogic(formFieldLogicFilter);
    }

    return formFieldLogicFilter;
}
