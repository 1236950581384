import React, { HTMLInputTypeAttribute, useId, useState, useEffect } from 'react';
import { AddressField as AddressFieldType, AddressFieldInput, FieldError } from '../../../../generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, AddressFieldValue } from '@/hooks/useGravityForm';
import Select from 'react-select';
// const { getData, getName } = require('country-list');
import { getData, getName } from 'country-list';

interface AddressFieldTypeWithInput extends AddressFieldType {
    key: string;
    placeholder: HTMLInputTypeAttribute;
}
interface Props {
    field: AddressFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
}

const DEFAULT_VALUE: AddressFieldInput = {};

const AUTOCOMPLETE_ATTRIBUTES: { [key: string]: string } = {
    street: 'address-line1',
    lineTwo: 'address-line2',
    city: 'address-level2',
    state: 'address-level1',
    country: 'country-name'
};

export default function AddressField({ field, fieldErrors, showField }: Props) {
    const { id, type, label, description, cssClass, inputs, isRequired, defaultCountry } = field;
    const firstCountry = getName(defaultCountry);
    const [selectedCountry, setSelectedCountry] = useState({
        value: defaultCountry || 'US',
        label: firstCountry
    });
    const [countries, setCountries] = useState([]);

    const htmlId = useId();
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as AddressFieldValue | undefined;
    const addressValues = fieldValue?.addressValues || DEFAULT_VALUE;

    useEffect(() => {
        const countries = getData();
        const countriesOptions = countries.map(({ code, name }) => {
            return {
                value: code,
                label: name
            };
        });
        setCountries(countriesOptions);
    }, []);

    function handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
        const { name, value } = event.target;
        const newAddressValues = { ...addressValues, [name]: value };

        dispatch({
            type: ACTION_TYPES.updateAddressFieldValue,
            fieldValue: {
                id,
                addressValues: newAddressValues
            }
        });
    }

    const handleCountryChange = selectedOption => {
        setSelectedCountry(selectedOption);
        handleChange({
            target: {
                name: 'country',
                value: selectedOption.value
            }
        });
    };

    if (!showField) return null;

    return (
        <fieldset id={htmlId} className={`gfield gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            <legend>{label}</legend>
            {inputs?.map((input: AddressFieldTypeWithInput) => {
                const inputKey = input?.key; //as keyof AddressFieldInput;
                const inputLabel = input?.label || '';
                const placeholder = input?.placeholder || '';

                if (inputKey === 'country') {
                    return (
                        <div key={inputKey}>
                            {label && <label htmlFor={`input_${inputKey}`}>Country</label>}
                            <Select
                                instanceId={inputKey}
                                inputId="country"
                                name={`input_${inputKey}`}
                                options={countries}
                                value={selectedCountry}
                                onChange={handleCountryChange}
                                isSearchable
                                placeholder="Select a country"
                                className="mt-1 block w-full "
                            />
                        </div>
                    );
                } else {
                    return (
                        <div key={inputKey}>
                            {label && <label htmlFor={`input_${inputKey}`}>{inputLabel}</label>}
                            <input
                                type="text"
                                name={`input_${inputKey}`}
                                id={htmlId}
                                placeholder={placeholder}
                                required={Boolean(isRequired)}
                                autoComplete={AUTOCOMPLETE_ATTRIBUTES[inputKey]}
                                defaultValue={addressValues?.[inputKey] ?? ''}
                                onChange={handleChange}
                            />
                        </div>
                    );
                }
            })}
            {description ? <p className="field-description">{description}</p> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
        </fieldset>
    );
}
