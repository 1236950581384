export function showConfirmationMessage(formData, confirmationMessages) {
    const conditionalConfirmationMessage = confirmationMessages?.find(confirmation => {
        const { logicType, rules } = confirmation?.conditionalLogic;
        const showOrHideArray = [];
        for (const rule of rules) {
            const { operator, fieldId, value } = rule;
            const formFieldFilter = formData.filter(f => f.id === fieldId).reverse();
            const formFieldValue = formFieldFilter[0]?.value;
            if (operator === 'IS') {
                if (formFieldValue === value) {
                    showOrHideArray.push(true);
                } else {
                    if (!formFieldValue && !value) {
                        showOrHideArray.push(true);
                    }
                    showOrHideArray.push(false);
                }
            } else if (operator === 'IS_NOT') {
                if (formFieldValue === value) {
                    showOrHideArray.push(false);
                } else {
                    if (!formFieldValue && !value) {
                        showOrHideArray.push(false);
                    }
                    showOrHideArray.push(true);
                }
            }
        }

        let showOrHideArrayResult;
        if (logicType === 'ALL') showOrHideArrayResult = showOrHideArray.every(val => val === true);
        else showOrHideArrayResult = showOrHideArray.some(val => val === true);

        return showOrHideArrayResult;
    });
    return conditionalConfirmationMessage;
}
