import { TextAreaField as TextAreaFieldType, FieldError } from '@/generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValue } from '@/hooks/useGravityForm';
import { getFieldParamsValue } from '@/utils/getFieldParamsValue';

interface Props {
    field: TextAreaFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: NonNullable<unknown>;
}

const DEFAULT_VALUE = '';

export default function TextAreaField({ field, fieldErrors, params, showField }: Props) {
    const { id, type, label, description, cssClass, isRequired, adminLabel, labelPlacement } = field;
    const paramValue = adminLabel ? getFieldParamsValue(params, adminLabel) : null;
    const htmlId = `field_${id}`;
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValue | undefined;
    const value = paramValue || fieldValue?.value || DEFAULT_VALUE;

    if (!showField) return null;

    return (
        <div className={`gfield gfield-${type.toLowerCase()} flex flex-col ${cssClass}`.trim()}>
            {label && labelPlacement !== 'HIDDEN' && <label htmlFor={htmlId}>{label}</label>}
            {description ? <p className="field-description text-annotationText">{description}</p> : null}
            <textarea
                name={String(id)}
                id={htmlId}
                required={Boolean(isRequired)}
                defaultValue={value}
                onChange={event => {
                    dispatch({
                        type: ACTION_TYPES.updateTextAreaFieldValue,
                        fieldValue: {
                            id,
                            value: event.target.value
                        }
                    });
                }}
                style={{ boxShadow: 'none' }}
            />
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
            <style jsx>{`
                .gfield-textarea textarea:focus {
                    box-shadow: none;
                }
            `}</style>
        </div>
    );
}
