import { NameField as NameFieldType, FieldError } from '@/generated/graphql';
// import useGravityForm from '@/hooks/useGravityForm';

interface Props {
    field: NameFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: any;
}

// const DEFAULT_VALUE: NameInputProperty | NameFieldInput = {};

// const AUTOCOMPLETE_ATTRIBUTES: { [key: string]: string } = {
//     prefix: 'honorific-prefix',
//     first: 'given-name',
//     middle: 'additional-name',
//     last: 'family-name',
//     suffix: 'honorific-suffix'
// };

export default function NameField({ field, fieldErrors, showField }: Props) {
    const { databaseId, type, label, description, cssClass } = field;
    const htmlId = `field_${databaseId}`;
    // const { state } = useGravityForm();
    // const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as NameFieldValue | undefined;
    // const nameValues: any = fieldValue?.nameValues || DEFAULT_VALUE;

    // const prefixInput = inputs?.find(input => input?.databaseId === 'prefix');
    // const otherInputs = inputs?.filter(input => input?.databaseId !== 'prefix') || [];

    // function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    //     const { name, value } = event.target;
    //     const newNameValues: any = { ...nameValues, [name]: value };

    //     dispatch({
    //         type: ACTION_TYPES.updateNameFieldValue,
    //         fieldValue: {
    //             id,
    //             nameValues: newNameValues?.prefix || null
    //         }
    //     });
    // }

    if (!showField) return null;

    return (
        <fieldset id={htmlId} className={`gfield gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            <legend>{label}</legend>
            {/* {prefixInput ? (
    <>
        <select
            name={String(prefixInput.id)}
            id={`input_${id}_${prefixInput.id}`}
            autoComplete={AUTOCOMPLETE_ATTRIBUTES.prefix}
            required={Boolean(isRequired)}
            value={nameValues?.prefix || ''}
            onChange={handleChange}>
            <option value=""></option>
            {prefixInput.choices?.map(choice => (
                <option key={choice?.value} value={String(choice?.value)}>
                    {String(choice?.text)}
                </option>
            ))}
        </select>
        <label htmlFor={`input_${id}_${prefixInput.databaseId}`}>{prefixInput.label}</label>
    </>
) : null}
{otherInputs.map(input => {
    const key = input?.databaseId as keyof NameInputProperty;
    const inputLabel = input?.label || '';
    const placeholder = input?.placeholder || '';
    return (
    <div key={key}>
            <input
                type="text"
                name={String(key)}
                id={`input_${id}_${key}`}
                placeholder={placeholder}
                autoComplete={AUTOCOMPLETE_ATTRIBUTES[key]}
                defaultValue={nameValues?.[key] || ''}
                onChange={handleChange}
            />
            <label htmlFor={`input_${id}_${key}`}>{inputLabel}</label>
        </div>
    );
})} */}
            {description ? <p className="field-description">{description}</p> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
        </fieldset>
    );
}
