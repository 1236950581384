import { useEffect, useId } from 'react';
import { TextField as TextFieldType, FieldError } from '@/generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValue } from '@/hooks/useGravityForm';
import { getFieldParamsValue } from '@/utils/getFieldParamsValue';

interface Props {
    field: TextFieldType;
    fieldErrors: FieldError[];
    params: NonNullable<unknown>;
    showField: boolean;
}

export default function TextField({ field, fieldErrors, params, showField }: Props) {
    const { id, type, label, description, cssClass, isRequired, placeholder, adminLabel, labelPlacement, visibility, defaultValue } = field;
    const paramValue = adminLabel ? getFieldParamsValue(params, adminLabel) : null;
    const htmlId = useId();
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValue | undefined;
    const value = paramValue || fieldValue?.value || defaultValue || '';
    const hideField = visibility === 'HIDDEN';

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.updateTextFieldValue,
            fieldValue: {
                id,
                value
            }
        });
    }, [dispatch, id, value]);

    if (!showField) return null;

    return (
        <div className={`gfield block gfield-${type.toLowerCase()} ${hideField && 'hidden'} ${cssClass}`.trim()}>
            {label && labelPlacement !== 'HIDDEN' && <label htmlFor={htmlId}>{label}</label>}
            {description ? <div className="field-description text-annotationText">{description}</div> : null}
            <input
                type="text"
                name={String(id)}
                id={htmlId}
                className="dark:darkGray"
                required={Boolean(isRequired)}
                placeholder={placeholder || ''}
                defaultValue={paramValue || value}
                onChange={event => {
                    dispatch({
                        type: ACTION_TYPES.updateTextFieldValue,
                        fieldValue: {
                            id,
                            value: event.target.value
                        }
                    });
                }}
            />
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
        </div>
    );
}
