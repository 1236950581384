import React, { useState } from 'react';
import useGravityForm, { ACTION_TYPES } from '@/hooks/useGravityForm';
// import { FieldError } from '@/generated/graphql';

// interface Props {
//     field: Upload[];
//     fieldErrors: FieldError[];
//     showField: Boolean;
// }

// interface Upload {
//     id: string;
//     type: string;
//     label: string;
//     cssClass: string;
//     isRequired: boolean;
// }

export default function FileUploadField({ field, fieldErrors, showField }) {
    const { id, type, label, cssClass } = field;
    const [filename, setFilename] = useState('');
    const { dispatch } = useGravityForm();

    const inputFile = React.createRef<HTMLInputElement>();
    // const reader = new global.window.FileReader();

    // reader.onload = (e) => {
    // };

    const onInputChangeHandler = e => {
        const { target } = e;
        const { files } = target;
        dispatch({
            type: ACTION_TYPES.updateFileUploadValue,
            fieldValue: {
                id,
                fileUploadValues: e.target.files
            }
        });
        setFilename(files[0]?.name);
    };

    const onFileUploadHandler = e => {
        e.preventDefault();
        inputFile.current.click();
    };

    if (!showField) return null;

    return (
        <div className={`gfield block rounded-md bg-slate-300 p-2 gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            <input type="file" ref={inputFile} hidden={true} onChange={e => onInputChangeHandler(e)} />
            <a className="font-bold text-lightBlue" onClick={e => onFileUploadHandler(e)} onKeyDown={onFileUploadHandler} role="presentation">
                {label} <span className="font-normal text-black">{filename}</span>
            </a>
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
        </div>
    );
}
