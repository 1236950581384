// eslint-disable react-hooks/exhaustive-deps
// import { gql } from '@apollo/client';
import { useEffect, useMemo, useCallback } from 'react';
import cx from 'classnames';
import { CheckboxField as CheckboxFieldType, CheckboxFieldInput, FieldError, CheckboxFieldChoice } from '@/generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, CheckboxFieldValue } from '@/hooks/useGravityForm';
interface Props {
    field: CheckboxFieldType;
    fieldErrors: FieldError[];
    params: any;
    handleFormValueChange: any;
    showField: boolean;
}

const DEFAULT_VALUE: CheckboxFieldInput[] = [];

export default function CheckboxField({ field, fieldErrors, handleFormValueChange, showField }: Props) {
    const { id, type, label, description, cssClass, inputs, choices, isRequired, labelPlacement, visibility } = field;
    const checkboxInputs = useMemo(() => choices?.map((choice: CheckboxFieldChoice, index) => ({ ...choice, id: inputs?.[index]?.id })) || [], [choices, inputs]);
    const htmlId = `field_${id}`;
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as CheckboxFieldValue | undefined;
    const checkboxValues = fieldValue?.checkboxValues || DEFAULT_VALUE;
    const labelVisible = label && labelPlacement !== 'HIDDEN';
    const hideField = visibility === 'HIDDEN';

    useEffect(() => {
        const checkboxValueFilter = checkboxInputs.filter(checkboxInput => checkboxInput?.isSelected).map(input => ({ inputId: input.id, value: input.value }));
        dispatch({
            type: ACTION_TYPES.updateCheckboxFieldValue,
            fieldValue: {
                id,
                checkboxValues: checkboxValueFilter
            }
        });
    }, [checkboxInputs, dispatch, id]);

    useCallback(() => {
        const checkboxValueFilter = checkboxInputs.filter(checkboxInput => checkboxInput.isSelected).map(input => ({ inputId: input.id, value: input.value }));
        handleFormValueChange(checkboxValueFilter, id);
    }, [id, checkboxInputs, handleFormValueChange]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value, checked } = event.target;
        const otherCheckboxValues = checkboxValues.filter((checkboxValue: CheckboxFieldInput) => checkboxValue.inputId !== Number(name));
        const newCheckboxValues = checked ? [...otherCheckboxValues, { inputId: Number(name), value }] : otherCheckboxValues;

        dispatch({
            type: ACTION_TYPES.updateCheckboxFieldValue,
            fieldValue: {
                id,
                checkboxValues: newCheckboxValues
            }
        });
        handleFormValueChange(newCheckboxValues, id);
    }

    if (!showField) return null;

    return (
        <fieldset id={htmlId} className={cx(`gfield gfield-${type.toLowerCase()} w-full ${cssClass}`.trim(), !labelVisible && 'ml-2', hideField && 'hidden')}>
            {labelVisible && <legend>{label}</legend>}
            {checkboxInputs.map(({ id, text, value, isSelected }) => (
                <div className={cx(['gfield-checkbox mb-3', labelVisible && 'ml-4 bsm:ml-5'])} key={id}>
                    <input
                        type="checkbox"
                        name={String(id)}
                        id={`input_${id}`}
                        required={Boolean(isRequired)}
                        defaultChecked={isSelected || false}
                        value={String(value)}
                        onChange={event => {
                            handleChange(event);
                        }}
                        className="checked:border-lightBlue checked:bg-lightBlue"
                        style={{ boxShadow: 'none' }}
                    />
                    <label htmlFor={`input_${id}`}>{text}</label>
                </div>
            ))}
            {description ? <p className="field-description">{description}</p> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
            {/* eslint-disable */}
            <style jsx scoped>{`
                .gfield-checkbox label {
                    margin-left: 10px;
                }
                .gfield-checkbox input,
                .gfield-checkbox input:focus {
                    box-shadow: none;
                }
            `}</style>
        </fieldset>
    );
}
