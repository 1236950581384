import { useEffect, useMemo } from 'react';
import { RadioField as RadioFieldType, FieldError, RadioFieldChoice } from '@/generated/graphql';
import useGravityForm, { ACTION_TYPES } from '@/hooks/useGravityForm';
import { changeContactCode } from '../changeContactCode';

interface Props {
    field: RadioFieldType;
    fieldErrors: FieldError[];
    params: any;
    handleFormValueChange: any;
    showField: boolean;
    formId: number;
}

export default function RadioField({ field, fieldErrors, handleFormValueChange, showField, formId }: Props) {
    const { state, dispatch } = useGravityForm();
    const { id, type, label, description, cssClass, choices, isRequired, labelPlacement, visibility } = field;
    const htmlId = `field_${id}`;
    const radioChoices = useMemo(() => choices?.map((choice: RadioFieldChoice, index) => ({ ...choice, id })) || [], [choices, id]);
    const hideField = visibility === 'HIDDEN';

    useEffect(() => {
        for (let choice of radioChoices) {
            if (choice.isSelected) {
                dispatch({
                    type: ACTION_TYPES.updateRadioFieldValue,
                    fieldValue: {
                        id,
                        value: choice.value
                    }
                });
                handleFormValueChange(choice.value, id);
            }
        }
    }, [radioChoices, dispatch, id]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value: targetValue } = event.target;

        dispatch({
            type: ACTION_TYPES.updateRadioFieldValue,
            fieldValue: {
                id,
                value: targetValue
            }
        });

        handleFormValueChange(targetValue, id);
        changeContactCode(event.target, state, dispatch, formId);
    }

    if (!showField) return null;

    return (
        <div id={htmlId} className={`gfield gfield-${type.toLowerCase()} ${hideField && 'hidden'} ${cssClass}`.trim()}>
            {labelPlacement !== 'HIDDEN' && <legend>{label}</legend>}
            {radioChoices?.map(input => {
                const text = input?.text || '';
                const inputValue = input?.value || '';
                const isSelected = input?.isSelected || false;

                return (
                    <div className="mb-2 ml-4 bsm:ml-5" key={inputValue}>
                        <input
                            type="radio"
                            className="checked:border-lightBlue checked:bg-lightBlue"
                            name={`choice_${id}`}
                            id={`choice_${id}_${inputValue}`}
                            required={Boolean(isRequired)}
                            defaultChecked={isSelected || false}
                            defaultValue={inputValue}
                            onChange={handleChange}
                            style={{ boxShadow: 'none' }}
                        />
                        <label htmlFor={`choice_${id}_${inputValue}`}>{text}</label>
                    </div>
                );
            })}
            {description ? <p className="field-description">{description}</p> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
            {/* eslint-disable */}
            <style jsx scoped>{`
                .gfield-radio input:focus {
                    box-shadow: none;
                }
                .gfield-radio input {
                    margin-bottom: 0.125rem;
                }

                .gfield-radio:not(.no-inline) div {
                    display: inline-block;
                }
            `}</style>
        </div>
    );
}
