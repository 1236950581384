import { EmailField as EmailFieldType, FieldError } from '@/generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, EmailFieldValue } from '@/hooks/useGravityForm';
import { getFieldParamsValue } from '@/utils/getFieldParamsValue';

interface Props {
    field: EmailFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: any;
}

const DEFAULT_VALUE = '';

export default function EmailField({ field, fieldErrors, params, showField }: Props) {
    const { id, type, label, description, cssClass, isRequired, placeholder, adminLabel } = field;
    const paramValue = adminLabel ? getFieldParamsValue(params, adminLabel) : null;
    const htmlId = `field_${id}`;
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as EmailFieldValue | undefined;
    const value = paramValue || fieldValue?.emailValues?.value || DEFAULT_VALUE;

    if (!showField) return null;

    return (
        <div className={`gfield gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            <label htmlFor={htmlId}>{label}</label>
            <input
                type="email"
                name={String(id)}
                id={htmlId}
                placeholder={placeholder || ''}
                required={Boolean(isRequired)}
                defaultValue={value}
                onChange={event => {
                    dispatch({
                        type: ACTION_TYPES.updateEmailFieldValue,
                        fieldValue: {
                            id,
                            emailValues: {
                                value: event.target.value
                            }
                        }
                    });
                }}
            />
            {description ? <p className="field-description">{description}</p> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
        </div>
    );
}
