import { ACTION_TYPES } from '@/hooks/useGravityForm';

export function changeContactCode(target, state, dispatch, formId) {
    const ancestorDivClassList = target.parentElement.parentElement.classList;
    const areYouJewishQuestion = ancestorDivClassList.contains('are-you-jewish');
    const believeInJesusQuestion = ancestorDivClassList.contains('believe-in-jesus');

    if (areYouJewishQuestion || believeInJesusQuestion) {
        let areYouJewishAnswer = 'No';
        let believeInJesusAnswer = 'No';
        let contactCode = 'GB';

        const areYouJewishInput = document.querySelector(`#gform_wrapper_${formId} .are-you-jewish div input[value="Yes"]`);
        const believeInJesusInput = document.querySelector(`#gform_wrapper_${formId} .believe-in-jesus div input[value="Yes"]`);

        if (areYouJewishInput?.checked === true) areYouJewishAnswer = 'Yes';
        if (believeInJesusInput?.checked === true) believeInJesusAnswer = 'Yes';

        const contactCodeFields = state
            .filter(field => field.checkboxValues)
            .find(checkboxField => {
                const { checkboxValues } = checkboxField;
                const isContactCodeField = checkboxValues.some(c => c.value === 'GB' || c.value === 'JB' || c.value === 'UJ' || c.value === 'UG');
                return isContactCodeField;
            });

        if (areYouJewishAnswer === 'No' && believeInJesusAnswer === 'Yes') contactCode = 'GB';
        else if (areYouJewishAnswer === 'Yes' && believeInJesusAnswer === 'Yes') contactCode = 'JB';
        else if (areYouJewishAnswer === 'Yes' && believeInJesusAnswer === 'No') contactCode = 'UJ';
        else if (areYouJewishAnswer === 'No' && believeInJesusAnswer === 'No') contactCode = 'UG';
        // else contactCode = 'GB';

        dispatch({
            type: ACTION_TYPES.updateCheckboxFieldValue,
            fieldValue: {
                id: contactCodeFields.id,
                checkboxValues: [{ inputId: parseFloat(contactCodeFields.id + '.1'), value: contactCode }]
            }
        });
    }
}
