import { HtmlField as HtmlFieldType, FieldError } from '@/generated/graphql';
// import useGravityForm from '@/hooks/useGravityForm';

interface Props {
    field: HtmlFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: any;
}

// const DEFAULT_VALUE = '';

export default function HtmlField({ field, fieldErrors, showField }: Props) {
    const { type, content, cssClass } = field;
    // const htmlId = `field_${id}`;
    // const { dispatch } = useGravityForm();

    if (!showField) return null;

    return (
        <div className={`gfield gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            {content ? <div dangerouslySetInnerHTML={{ __html: content }} className="field-description content-container" /> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
        </div>
    );
}
