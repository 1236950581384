import { FormField, FieldError } from '../../../generated/graphql';
import AddressField from './GravityFormsFields/AddressField';
import CheckboxField from './GravityFormsFields/CheckboxField';
import DateField from './GravityFormsFields/DateField';
import EmailField from './GravityFormsFields/EmailField';
import NameField from './GravityFormsFields/NameField';
import PhoneField from './GravityFormsFields/PhoneField';
import RadioField from './GravityFormsFields/RadioField';
import SelectField from './GravityFormsFields/SelectField';
import TextField from './GravityFormsFields/TextField';
import TextAreaField from './GravityFormsFields/TextAreaField';
import TimeField from './GravityFormsFields/TimeField';
import WebsiteField from './GravityFormsFields/WebsiteField';
import HtmlField from './GravityFormsFields/HtmlField';
import FileUploadField from './GravityFormsFields/FileUploadField';
import NumberField from './GravityFormsFields/NumberField';
import SectionField from './GravityFormsFields/SectionField';
import HiddenField from './GravityFormsFields/HiddenField';

interface Props {
    field: FormField;
    fieldErrors: FieldError[];
    params: any;
    handleFormValueChange: any;
    showField: boolean;
    formId: number;
}

export default function GravityFormsField(props: Props) {
    const fieldType = String(props.field.type).toLowerCase();
    // Test

    switch (fieldType) {
        case 'address':
            return <AddressField {...props} />;
        case 'checkbox':
            return <CheckboxField {...props} />;
        case 'date':
            return <DateField {...props} />;
        case 'email':
            return <EmailField {...props} />;
        case 'name':
            return <NameField {...props} />;
        case 'phone':
            return <PhoneField {...props} />;
        case 'radio':
            return <RadioField {...props} />;
        case 'select':
            return <SelectField {...props} />;
        case 'text':
            return <TextField {...props} />;
        case 'number':
            return <NumberField {...props} />;
        case 'textarea':
            return <TextAreaField {...props} />;
        case 'time':
            return <TimeField {...props} />;
        case 'website':
            return <WebsiteField {...props} />;
        case 'html':
            return <HtmlField {...props} />;
        case 'fileupload':
            return <FileUploadField {...props} />;
        case 'section':
            return <SectionField {...props} />;
        case 'hidden':
            return <HiddenField {...props} />;
        default:
            return <div className="m-2 block w-full bg-red-100 p-2">{`Field type not supported: ${props.field.type}.`}</div>;
    }
}
