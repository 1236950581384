import { useEffect } from 'react';
import { SelectField as SelectFieldType, FieldError, SelectFieldChoice } from '../../../../generated/graphql';
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValue } from '@/hooks/useGravityForm';

interface Props {
    field: SelectFieldType;
    fieldErrors: FieldError[];
    showField: boolean;
    params: unknown;
}

interface ExtendedGfFieldChoice extends SelectFieldChoice {
    isSelected?: boolean;
}

export default function SelectField({ field, fieldErrors, showField }: Props) {
    const { id, type, label, description, cssClass, isRequired, defaultValue, choices, labelPlacement } = field;
    const htmlId = `field_${id}`;
    const { state, dispatch } = useGravityForm();

    const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValue | undefined;
    const defaultValueFind = choices.find((choice: ExtendedGfFieldChoice) => (choice as any)?.isSelected);
    const value = String(defaultValueFind?.value) || fieldValue?.value;

    useEffect(() => {
        // console.log('wv', id, fieldValue);
        dispatch({
            type: ACTION_TYPES.updateSelectFieldValue,
            fieldValue: {
                id: id,
                value: value
            }
        });
    }, [value, id, dispatch]);

    if (!showField) return null;

    return (
        <div className={`gfield block gfield-${type.toLowerCase()} ${cssClass}`.trim()}>
            {labelPlacement !== 'HIDDEN' && (
                <label className="block pb-0" htmlFor={htmlId}>
                    {label}
                </label>
            )}
            <select
                name={String(id)}
                id={htmlId}
                required={Boolean(isRequired)}
                defaultValue={defaultValueFind?.value || value}
                onChange={event => {
                    dispatch({
                        type: ACTION_TYPES.updateSelectFieldValue,
                        fieldValue: {
                            id: id,
                            value: event.target.value
                        }
                    });
                }}
                style={{ boxShadow: 'none' }}
                className="w-full border-inherit py-2 dark:text-black">
                {choices?.map(choice => {
                    const { value, text } = choice;
                    return (
                        <option key={value || ''} value={value || ''}>
                            {text || ''}
                        </option>
                    );
                })}
            </select>
            {description ? <p className="field-description">{description}</p> : null}
            {fieldErrors?.length
                ? fieldErrors.map(fieldError => (
                      <p key={fieldError.id} className="error-message">
                          {fieldError.message}
                      </p>
                  ))
                : null}
            {/* eslint-disable */}
            <style jsx>{`
                .gfield-select select:focus {
                    box-shadow: none;
                    border-color: #000;
                }
            `}</style>
        </div>
    );
}
